<template>
  <v-fade-transition mode="out-in">
    <v-app>
      <div class="app-container d-md-none d-lg-none d-xl-none">
        <router-view />
      </div>
    </v-app>
  </v-fade-transition>
</template>

<script>
export default { name: "App" };
</script>

<style lang="scss"></style>
