import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
import { store } from "./store/store";
import {Html5QrcodeScanner} from "html5-qrcode"
import {Html5Qrcode} from "html5-qrcode"
import "./plugins";
import "@/assets/scss/_global.scss";
Vue.config.productionTip = false;


// plugin svgrender 
// import Vue plugin
import VueSvgInlinePlugin from "vue-svg-inline-plugin";

// import polyfills for IE if you want to support it
import "vue-svg-inline-plugin/src/polyfills";
// use Vue plugin without options
Vue.use(VueSvgInlinePlugin);

// use Vue plugin with options
VueSvgInlinePlugin.install(Vue, {
	attributes: {
		data: [ "src" ],
		remove: [ "alt" ]
	}
});

// end:: svgrender 

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

Vue.component('qrcode-scanner', {
  props: {
    qrbox: {
      type: Number,
      default: 250
    },
    fps: {
      type: Number,
      default: 30
    },
  },
  template: `<div id="reader"></div>`,
  mounted () {
    const config = {
      fps: this.fps,
      qrbox: this.qrbox,
    };

    const html5QrCode = new Html5Qrcode("reader", true);
    html5QrCode.start({ facingMode: "environment" }, config, this.qrCodeSuccessCallback, this.qrCodeFailCallback);
  },
  methods: {
    qrCodeSuccessCallback(decodedText, decodedResult) {
      this.$emit('result', decodedText, decodedResult);
    },
    qrCodeFailCallback(errorMessage) {
      console.log(errorMessage);
    }
  }
});

Vue.component(VueSlickCarousel)
Vue.component("VueSlickCarousel", VueSlickCarousel);
import VueApexCharts from 'vue-apexcharts'
import './registerServiceWorker'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");

